import React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { Layout, LoadingPage } from 'components';

const PreviewPage = ({ location }) => (
  <Layout location={location}>
    <LoadingPage text="Preview loading" />
  </Layout>
);

export default withPrismicPreviewResolver(PreviewPage);
